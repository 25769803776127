<template>
	<div v-if="theme">
		<h2 class="text-secondary">{{ theme.pageTitle }}</h2>

		<div class="row mb-5">
			<div class="col-12 col-lg-7 order-1 order-lg-0">
				<div v-html="theme.content"/>
			</div>
			<div class="col-12 col-lg-5 order-0 order-lg-1">
				<MediaEmbed :page="theme" />
			</div>
		</div>

		<div class="text-right">
			<router-link class="btn btn-secondary" :to="{ path: `/analyse/${theme.slug}/scan`}">
				{{ buttonTitle }}<span><icon icon="arrow-right"/></span>
			</router-link>
		</div>
	</div>
</template>

<script>
    import Icon from "@/components/Icon";
    import MediaEmbed from "@/components/MediaEmbed";

    export default {
        name: 'AnalyseThema',
        components: {Icon, MediaEmbed},
        data() {
            return {
                buttonTitle: 'Start analyse',
                theme: null,
            }
        },
        created() {
            this.theme = this.$store.getters.getThemeBySlug(this.$route.params.thema);
        },
        mounted() {
            const breadcrumbs = [
                {
                    'label': 'Home',
                    'path': '/'
                },
                {
                    'label': 'Analyse',
                    'path': '/analyse/'
                },
                {
                    'label': this.theme.pageTitle,
                },
            ];
            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        },
        methods: {
            toggleClass(index) {
                this.themes[index].isActive = !this.themes[index].isActive;
            }
        },
    }
</script>
